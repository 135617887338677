:root {
  --color-accent-aqua-100: #d3f5ec;
  --color-accent-aqua-300: #72e0c6;
  --color-accent-aqua-500: #19b891;
  --color-accent-aqua-700: #0a7057;
  --color-accent-aqua-900: #024031;
  --color-accent-aqua-alpha-100: #19b89133;
  --color-accent-aqua-alpha-200: #19b89166;
  --color-accent-aqua-alpha-300: #19b89199;
  --color-accent-aqua-alpha-400: #19b891cc;
  --color-accent-aubergine-100: #f6e3ff;
  --color-accent-aubergine-300: #e2a8ff;
  --color-accent-aubergine-500: #bb58ed;
  --color-accent-aubergine-700: #6f0da1;
  --color-accent-aubergine-900: #33004d;
  --color-accent-aubergine-alpha-100: #bb58ed33;
  --color-accent-aubergine-alpha-200: #bb58ed66;
  --color-accent-aubergine-alpha-300: #bb58ed99;
  --color-accent-aubergine-alpha-400: #bb58edcc;
  --color-accent-coral-100: #f7d7df;
  --color-accent-coral-300: #f2a0b4;
  --color-accent-coral-500: #eb5e81;
  --color-accent-coral-700: #911d3a;
  --color-accent-coral-900: #4d0315;
  --color-accent-coral-alpha-100: #eb5e8133;
  --color-accent-coral-alpha-200: #eb5e8166;
  --color-accent-coral-alpha-300: #eb5e8199;
  --color-accent-coral-alpha-400: #eb5e81cc;
  --color-accent-earth-100: #f5ded3;
  --color-accent-earth-300: #ebb59b;
  --color-accent-earth-500: #d4855d;
  --color-accent-earth-700: #7a4930;
  --color-accent-earth-900: #452719;
  --color-accent-earth-alpha-100: #d4855d33;
  --color-accent-earth-alpha-200: #d4855d66;
  --color-accent-earth-alpha-300: #d4855d99;
  --color-accent-earth-alpha-400: #d4855dcc;
  --color-accent-lime-100: #e6f2c2;
  --color-accent-lime-300: #c6e567;
  --color-accent-lime-500: #7ea800;
  --color-accent-lime-700: #526b07;
  --color-accent-lime-900: #263300;
  --color-accent-lime-alpha-100: #7ea80033;
  --color-accent-lime-alpha-200: #7ea80066;
  --color-accent-lime-alpha-300: #7ea80099;
  --color-accent-lime-alpha-400: #7ea800cc;
  --color-accent-magenta-100: #ffdbf6;
  --color-accent-magenta-300: #f59dde;
  --color-accent-magenta-500: #e352be;
  --color-accent-magenta-700: #991577;
  --color-accent-magenta-900: #40002f;
  --color-accent-magenta-alpha-100: #e352be33;
  --color-accent-magenta-alpha-200: #e352be66;
  --color-accent-magenta-alpha-300: #e352be99;
  --color-accent-magenta-alpha-400: #e352becc;
  --color-accent-rust-100: #fadfd7;
  --color-accent-rust-300: #faa88e;
  --color-accent-rust-500: #f5683b;
  --color-accent-rust-700: #962c0b;
  --color-accent-rust-900: #4d1300;
  --color-accent-rust-alpha-100: #f5683b33;
  --color-accent-rust-alpha-200: #f5683b66;
  --color-accent-rust-alpha-300: #f5683b99;
  --color-accent-rust-alpha-400: #f5683bcc;
  --color-accent-sea-100: #dce7fc;
  --color-accent-sea-300: #afc0e3;
  --color-accent-sea-500: #7891c2;
  --color-accent-sea-700: #455c8a;
  --color-accent-sea-900: #242e42;
  --color-accent-sea-alpha-100: #7891c233;
  --color-accent-sea-alpha-200: #7891c266;
  --color-accent-sea-alpha-300: #7891c299;
  --color-accent-sea-alpha-400: #7891c2cc;
  --color-accent-sky-100: #d2f0fa;
  --color-accent-sky-300: #91d7ed;
  --color-accent-sky-500: #33a7cc;
  --color-accent-sky-700: #0f6e8c;
  --color-accent-sky-900: #073a4a;
  --color-accent-sky-alpha-100: #33a7cc33;
  --color-accent-sky-alpha-200: #33a7cc66;
  --color-accent-sky-alpha-300: #33a7cc99;
  --color-accent-sky-alpha-400: #33a7cccc;
  --color-neutral-100: #ebebed;
  --color-neutral-200: #d3d5d9;
  --color-neutral-300: #adaeb2;
  --color-neutral-400: #929396;
  --color-neutral-500: #76767a;
  --color-neutral-50: #f9f9fa;
  --color-neutral-600: #545559;
  --color-neutral-700: #2e3033;
  --color-neutral-800: #212326;
  --color-neutral-900: #16161a;
  --color-neutral-alpha-dark-100: #16161a33;
  --color-neutral-alpha-dark-200: #16161a66;
  --color-neutral-alpha-dark-25: #16161a0d;
  --color-neutral-alpha-dark-300: #16161a99;
  --color-neutral-alpha-dark-400: #16161acc;
  --color-neutral-alpha-dark-50: #16161a1a;
  --color-neutral-alpha-light-100: #fff3;
  --color-neutral-alpha-light-200: #fff6;
  --color-neutral-alpha-light-25: #ffffff0d;
  --color-neutral-alpha-light-300: #fff9;
  --color-neutral-alpha-light-400: #fffc;
  --color-neutral-alpha-light-50: #ffffff1a;
  --color-neutral-black: #000;
  --color-neutral-white: #fff;
  --color-primary-brand-100: #d6ddff;
  --color-primary-brand-200: #b8c3ff;
  --color-primary-brand-300: #99a9ff;
  --color-primary-brand-400: #6d84f7;
  --color-primary-brand-500: #4661eb;
  --color-primary-brand-50: #edf0ff;
  --color-primary-brand-600: #223cbd;
  --color-primary-brand-700: #0e238c;
  --color-primary-brand-800: #091657;
  --color-primary-brand-900: #000933;
  --color-primary-brand-alpha-100: #4661eb33;
  --color-primary-brand-alpha-200: #4661eb66;
  --color-primary-brand-alpha-300: #4661eb99;
  --color-primary-brand-alpha-400: #4661ebcc;
  --color-primary-brand-alpha-50: #4661eb1a;
  --color-support-danger-100: #ffdbe4;
  --color-support-danger-200: #ffbacb;
  --color-support-danger-300: #ff8aa7;
  --color-support-danger-400: #ff5e87;
  --color-support-danger-500: #f53667;
  --color-support-danger-50: #ffebf0;
  --color-support-danger-600: #bf1b44;
  --color-support-danger-700: #8f0e2e;
  --color-support-danger-800: #57061a;
  --color-support-danger-900: #33000d;
  --color-support-danger-alpha-100: #f5366733;
  --color-support-danger-alpha-200: #f5366766;
  --color-support-danger-alpha-300: #f5366799;
  --color-support-danger-alpha-400: #f53667cc;
  --color-support-danger-alpha-50: #f536671a;
  --color-support-success-100: #c9ffcd;
  --color-support-success-200: #a1f0a6;
  --color-support-success-300: #7ee084;
  --color-support-success-400: #42cf4c;
  --color-support-success-500: #22bd49;
  --color-support-success-50: #e2ffe4;
  --color-support-success-600: #149934;
  --color-support-success-700: #0b6e21;
  --color-support-success-800: #055214;
  --color-support-success-900: #003307;
  --color-support-success-alpha-100: #22bd4933;
  --color-support-success-alpha-200: #22bd4966;
  --color-support-success-alpha-300: #22bd4999;
  --color-support-success-alpha-400: #22bd49cc;
  --color-support-success-alpha-50: #22bd491a;
  --color-support-warning-100: #faecc0;
  --color-support-warning-200: #f5de98;
  --color-support-warning-300: #ebca67;
  --color-support-warning-400: #d9b23d;
  --color-support-warning-500: #c2981d;
  --color-support-warning-50: #fff7e0;
  --color-support-warning-600: #a88313;
  --color-support-warning-700: #7d600a;
  --color-support-warning-800: #523e03;
  --color-support-warning-900: #332600;
  --color-support-warning-alpha-100: #c2981d33;
  --color-support-warning-alpha-200: #c2981d66;
  --color-support-warning-alpha-300: #c2981d99;
  --color-support-warning-alpha-400: #c2981dcc;
  --color-support-warning-alpha-50: #c2981d1a;
  --height-default: 36px;
  --height-small: 28px;
  --height-x-small: 24px;
  --height-xx-small: 20px;
  --radius-default: 10px;
  --radius-full: 9999px;
  --radius-lowest: 4px;
  --radius-minimal: 8px;
  --radius-none: 0px;
  --radius-rounded: 12px;
  --font-body-l-medium: 500 16px / 28px Inter;
  --font-body-l-regular: 400 16px / 28px Inter;
  --font-body-md-medium: 500 14px / 24px Inter;
  --font-body-md-regular: 400 14px / 24px Inter;
  --font-body-sm-medium: 500 12px / 20px Inter;
  --font-body-sm-regular: 400 12px / 20px Inter;
  --font-body-xl-medium: 500 20px / 32px Inter;
  --font-body-xl-regular: 400 20px / 32px Inter;
  --font-body-xs-medium: 500 10px / 16px Inter;
  --font-body-xs-regular: 400 10px / 16px Inter;
  --font-buttons-md: 500 14px / 16px Inter;
  --font-buttons-sm: 500 12px / 16px Inter;
  --font-code-medium: 500 16px / 20px Inconsolata;
  --font-code-regular: 400 16px / 20px Inconsolata;
  --font-headings-h1: 800 30px / 40px Inter;
  --font-headings-h2: 700 24px / 32px Inter;
  --font-headings-h3: 600 20px / 24px Inter;
  --font-headings-h4: 500 16px / 20px Inter;
  --font-headings-h5: 500 14px / 16px Inter;
  --font-headings-h6: 500 12px / 16px Inter;
  --focus-error: 0px 0px 0px 4px #f5366733;
  --focus-primary: 0px 0px 0px 4px #4661eb33;
  --shadow-l: 0px 4px 40px -10px #0000004d;
  --shadow-m: 0px 8px 4px 0px #0000001a, 0px 0px 2px 0px #0000000d;
  --shadow-s: 0px 4px 4px 0px #0000001a;
  --shadow-xl: 0px 4px 80px -10px #00000080;
  --shadow-xs: 0px 2px 2px 0px #00000014;
  --shadow-xxs: 0px 1px 2px 0px #0000000d;
  --color-bg-default: var(--color-neutral-white);
  --color-bg-subtle: var(--color-neutral-50);
  --color-bg-hover: var(--color-neutral-100);
  --color-bg-hover-primary: var(--color-primary-brand-alpha-100);
  --color-bg-disabled: var(--color-neutral-100);
  --color-bg-disabled-overlay: var(--color-neutral-alpha-light-300);
  --color-bg-accent-1: var(--color-accent-magenta-100);
  --color-bg-accent-2: var(--color-accent-lime-100);
  --color-bg-accent-3: var(--color-accent-rust-100);
  --color-bg-elevated-default: var(--color-bg-default);
  --color-bg-elevated-hover: var(--color-bg-hover);
  --color-bg-emphasis-neutral: var(--color-neutral-800);
  --color-bg-emphasis-neutral-subtle: var(--color-neutral-alpha-dark-50);
  --color-bg-emphasis-primary: var(--color-primary-brand-500);
  --color-bg-emphasis-primary-subtle: var(--color-primary-brand-alpha-100);
  --color-bg-danger: var(--color-support-danger-50);
  --color-bg-success: var(--color-support-success-50);
  --color-bg-warning: var(--color-support-warning-50);
  --color-fg-default: var(--color-neutral-black);
  --color-fg-muted: var(--color-neutral-500);
  --color-fg-subtle: var(--color-neutral-300);
  --color-fg-primary: var(--color-primary-brand-500);
  --color-fg-accent-1: var(--color-accent-magenta-500);
  --color-fg-accent-2: var(--color-accent-lime-500);
  --color-fg-accent-3: var(--color-accent-rust-500);
  --color-fg-onemphasis-default: var(--color-neutral-white);
  --color-fg-onemphasis-subtle: var(--color-neutral-alpha-light-300);
  --color-fg-danger: var(--color-support-danger-600);
  --color-fg-success: var(--color-support-success-600);
  --color-fg-warning: var(--color-support-warning-600);
  --color-border-default: var(--color-neutral-200);
  --color-border-muted: var(--color-neutral-100);
  --color-button-primary-bg-default: var(--color-primary-brand-500);
  --color-button-primary-bg-hover: var(--color-primary-brand-600);
  --color-button-primary-bg-disabled: var(--color-primary-brand-200);
  --color-border-primary: var(--color-primary-brand-500);
  --color-button-primary-fg-default: var(--color-neutral-white);
  --color-button-primary-fg-disabled: var(--color-button-primary-fg-default);
  --color-button-primary-danger-bg-default: var(--color-support-danger-500);
  --color-button-secondary-bg: var(--color-neutral-white);
  --color-button-secondary-fg: var(--color-neutral-900);
  --color-button-secondary-border: var(--color-neutral-200);
  --color-input-bg: var(--color-neutral-white);
  --color-notebook-cell-bg-default: var(--color-neutral-white);
  --color-input-fg-input: var(--color-fg-default);
  --color-input-fg-placeholder: var(--color-fg-subtle);
  --color-input-border-default: var(--color-border-default);
  --color-tag-fg-default: var(--color-fg-primary);
  --color-tag-fg-placeholder: var(--color-primary-brand-alpha-300);
  --color-tag-bg: var(--color-primary-brand-alpha-50);
  --color-border-danger: var(--color-support-danger-500);
  --color-border-success: var(--color-support-success-500);
  --color-border-warning: var(--color-support-warning-500);
  --color-notebook-cell-bg-query: var(--color-neutral-50);
  --color-notebook-cell-bg-emphasis-neutral: var(--color-neutral-100);
  --color-notebook-cell-bg-text-selected: var(--color-primary-brand-alpha-100);
  --color-notebook-cell-bg-cell-selected: var(--color-primary-brand-alpha-50);
  --color-notebook-cell-bg-text-highlighted: var(--color-support-warning-100);
  --color-tab-bg-default: var(--color-neutral-alpha-dark-25);
  --color-nav-bar-bg-selected: var(--color-neutral-white);
  --color-tab-bg-selected: var(--color-neutral-white);
  --color-tab-text-selected: var(--color-fg-default);
  --color-tab-text-default: var(--color-fg-muted);
  --color-tab-border-selected: var(--color-neutral-100);
  --color-border-focus-primary: var(--color-primary-brand-alpha-100);
  --color-input-border-hover: var(--color-neutral-300);
  --color-border-focus-error: var(--color-support-danger-alpha-100);
  --color-button-primary-danger-bg-hover: var(--color-support-danger-600);
  --color-button-primary-danger-bg-disabled: var(--color-support-danger-200);
  --color-button-primary-danger-fg-default: var(--color-neutral-white);
  --color-button-primary-danger-fg-disabled: var(--color-button-primary-danger-fg-default);
  --color-button-secondary-danger-bg-default: var(--color-neutral-white);
  --color-button-secondary-danger-bg-hover: var(--color-support-danger-50);
  --color-button-secondary-danger-fg-default: var(--color-fg-danger);
  --color-button-secondary-danger-fg-disabled: var(--color-support-danger-300);
}

body[data-theme="dark"] {
  --color-bg-accent-1: var(--color-accent-magenta-900);
  --color-bg-accent-2: var(--color-accent-lime-700);
  --color-bg-accent-3: var(--color-accent-rust-700);
  --color-bg-danger: var(--color-support-danger-alpha-50);
  --color-bg-default: var(--color-neutral-900);
  --color-bg-disabled-overlay: var(--color-neutral-alpha-dark-300);
  --color-bg-disabled: var(--color-neutral-700);
  --color-bg-elevated-default: var(--color-neutral-800);
  --color-bg-elevated-hover: var(--color-neutral-700);
  --color-bg-emphasis-neutral-subtle: var(--color-neutral-alpha-light-100);
  --color-bg-emphasis-neutral: var(--color-neutral-800);
  --color-bg-emphasis-primary-subtle: var(--color-primary-brand-alpha-200);
  --color-bg-emphasis-primary: var(--color-primary-brand-500);
  --color-bg-hover-primary: var(--color-primary-brand-alpha-200);
  --color-bg-hover: var(--color-neutral-700);
  --color-bg-subtle: var(--color-neutral-800);
  --color-bg-success: var(--color-support-success-alpha-50);
  --color-bg-warning: var(--color-support-warning-alpha-50);
  --color-border-danger: var(--color-support-danger-500);
  --color-border-default: var(--color-neutral-600);
  --color-border-focus-error: var(--color-support-danger-alpha-200);
  --color-border-focus-primary: var(--color-primary-brand-alpha-200);
  --color-border-muted: var(--color-neutral-700);
  --color-border-primary: var(--color-primary-brand-500);
  --color-border-success: var(--color-support-success-500);
  --color-border-warning: var(--color-support-warning-500);
  --color-button-primary-bg-default: var(--color-primary-brand-500);
  --color-button-primary-bg-disabled: var(--color-primary-brand-700);
  --color-button-primary-bg-hover: var(--color-primary-brand-400);
  --color-button-primary-danger-bg-default: var(--color-support-danger-500);
  --color-button-primary-danger-bg-disabled: var(--color-support-danger-700);
  --color-button-primary-danger-bg-hover: var(--color-support-danger-400);
  --color-button-primary-danger-fg-default: var(--color-neutral-white);
  --color-button-primary-danger-fg-disabled: var(--color-neutral-alpha-light-200);
  --color-button-primary-fg-default: var(--color-neutral-white);
  --color-button-primary-fg-disabled: var(--color-neutral-alpha-light-200);
  --color-button-secondary-bg: var(--color-neutral-800);
  --color-button-secondary-border: var(--color-neutral-600);
  --color-button-secondary-danger-bg-default: var(--color-neutral-800);
  --color-button-secondary-danger-bg-hover: var(--color-support-danger-900);
  --color-button-secondary-danger-fg-default: var(--color-fg-danger);
  --color-button-secondary-danger-fg-disabled: var(--color-support-danger-alpha-300);
  --color-button-secondary-fg: var(--color-neutral-white);
  --color-fg-accent-1: var(--color-accent-magenta-500);
  --color-fg-accent-2: var(--color-accent-lime-500);
  --color-fg-accent-3: var(--color-accent-rust-500);
  --color-fg-danger: var(--color-support-danger-400);
  --color-fg-default: var(--color-neutral-white);
  --color-fg-muted: var(--color-neutral-300);
  --color-fg-onemphasis-default: var(--color-neutral-white);
  --color-fg-onemphasis-subtle: var(--color-neutral-alpha-light-300);
  --color-fg-primary: var(--color-primary-brand-400);
  --color-fg-subtle: var(--color-neutral-500);
  --color-fg-success: var(--color-support-success-400);
  --color-fg-warning: var(--color-support-warning-400);
  --color-input-bg: var(--color-neutral-700);
  --color-input-border-default: var(--color-border-default);
  --color-input-border-hover: var(--color-neutral-500);
  --color-input-fg-input: var(--color-fg-default);
  --color-input-fg-placeholder: var(--color-fg-subtle);
  --color-nav-bar-bg-selected: var(--color-neutral-700);
  --color-notebook-cell-bg-cell-selected: var(--color-primary-brand-alpha-100);
  --color-notebook-cell-bg-default: var(--color-neutral-800);
  --color-notebook-cell-bg-emphasis-neutral: var(--color-neutral-600);
  --color-notebook-cell-bg-query: var(--color-neutral-700);
  --color-notebook-cell-bg-text-highlighted: var(--color-support-warning-alpha-200);
  --color-notebook-cell-bg-text-selected: var(--color-primary-brand-alpha-200);
  --color-tab-bg-default: var(--color-neutral-alpha-light-50);
  --color-tab-bg-selected: var(--color-neutral-700);
  --color-tab-border-selected: var(--color-neutral-600);
  --color-tab-text-default: var(--color-fg-muted);
  --color-tab-text-selected: var(--color-fg-default);
  --color-tag-bg: var(--color-primary-brand-alpha-300);
  --color-tag-fg-default: var(--color-primary-brand-100);
  --color-tag-fg-placeholder: var(--color-primary-brand-400);
}
/*# sourceMappingURL=variables.312a1fc0.css.map */
